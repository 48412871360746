import { ACTION_TYPE } from '../constants/actionType'

const isSamePair = (a, b) =>
  a.address === b.address &&
  a.dex === b.dex &&
  a.baseToken.address.toLowerCase() === b.baseToken.address.toLowerCase() &&
  a.quoteToken.address.toLowerCase() === b.quoteToken.address.toLowerCase()

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.addFav: {
      const { pair } = action.payload

      if (state.find(f => isSamePair(f, pair))) {
        return state
      }

      return [...state, pair]
    }
    case ACTION_TYPE.deleteFav: {
      const { pair } = action.payload

      return state.filter(f => !isSamePair(f, pair))
    }
    case ACTION_TYPE.loadStorage: {
      const { favList } = action.payload
      const pairs =
        favList?.filter(pair => !state.find(f => isSamePair(f, pair))) ?? []
      return [...state, ...pairs]
    }
    default: {
      return state
    }
  }
}

const initialState = []

const initialize = (initialState = []) => initialState

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
