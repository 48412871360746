import { getAddress } from 'ethers/lib/utils'
import { DEX } from './constants/dex'

export const rpcUrl = 'https://bsc-dataseed1.binance.org/'
export const explorerUrl = 'https://www.bscscan.com/'

export const defaultTokenOptions = [
  { address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', symbol: 'WBNB' },
  { address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', symbol: 'BUSD' },
  { address: '0x55d398326f99059fF775485246999027B3197955', symbol: 'USDT' },
  { address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', symbol: 'USDC' },
  { address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', symbol: 'DAI' },
  { address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', symbol: 'CAKE' },
  { address: '0x084bb94e93891D74579B54Ab63ED24C4ef9cd5Ef', symbol: 'COUPON' },
  { address: '0xc350CaA89Eb963D5D6b964324A0a7736D8d65533', symbol: 'INFTEE' },
  { address: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F', symbol: 'ALPACA' },
  { address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', symbol: 'BTCB' },
  { address: '0x1FC2C1C218B079F21b6FdD52b07e4fd256c0A17f', symbol: 'WWIN' },
  { address: '0x557233E794d1a5FbCc6D26dca49147379ea5073c', symbol: 'ALI' },
  { address: '0x270178366a592bA598C2e9d2971DA65f7bAa7C86', symbol: 'KSW' },
  { address: '0x401D9c80B8F349c462C1f85F16f0E7158216d98B', symbol: 'LOREM' },
  { address: '0xbbA64dc871BE2fE2114338442Dd3E45893310dcE', symbol: 'META' },
  { address: '0xf486ad071f3bEE968384D2E39e2D8aF0fCf6fd46', symbol: 'VELO' },
].map((t) => ({ address: getAddress(t.address), symbol: t.symbol }))

const getTokenOptionFromSymbol = (symbol) =>
  defaultTokenOptions.find(
    (token) => token.symbol.toLowerCase() === symbol.toLowerCase()
  )

export const defaultDex = DEX.PancakeSwapV2
export const defaultBaseToken = getTokenOptionFromSymbol('WBNB')
export const defaultQuoteToken = getTokenOptionFromSymbol('BUSD')

export const fullNumPastBlock = 2500
export const minNumPastLogsPerStart = 250
export const maxGetPastLogsPerStart = 10
export const timeZoneUTC = 7
export const intervalGetLogs = 5000

export const tokenPairUSD = {
  [getTokenOptionFromSymbol('WBNB').address]: {
    isStable: false,
    baseToken: getTokenOptionFromSymbol('WBNB'),
    quoteToken: getTokenOptionFromSymbol('BUSD'),
    dex: DEX.PancakeSwapV2,
  },
  [getTokenOptionFromSymbol('BUSD').address]: {
    isStable: true,
    baseToken: getTokenOptionFromSymbol('BUSD'),
  },
  [getTokenOptionFromSymbol('USDT').address]: {
    isStable: true,
    baseToken: getTokenOptionFromSymbol('USDT'),
  },
  [getTokenOptionFromSymbol('USDC').address]: {
    isStable: true,
    baseToken: getTokenOptionFromSymbol('USDC'),
  },
  [getTokenOptionFromSymbol('DAI').address]: {
    isStable: true,
    baseToken: getTokenOptionFromSymbol('DAI'),
  },
  [getTokenOptionFromSymbol('CAKE').address]: {
    isStable: false,
    baseToken: getTokenOptionFromSymbol('CAKE'),
    quoteToken: getTokenOptionFromSymbol('BUSD'),
    dex: DEX.PancakeSwapV2,
  },
}
