import { DeleteForever } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  createFilterOptions,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { getAddress } from 'ethers/lib/utils'
import { useContext, useState } from 'react'
import { ACTION_TYPE } from '../constants/actionType'
import { DispatchContext, StateContext } from '../constants/contexts'
import { DEX } from '../constants/dex'
import {
  defaultBaseToken,
  defaultDex,
  defaultQuoteToken,
  defaultTokenOptions,
} from '../setting'
import { getPair, getTokenInfo } from '../utils/contractUtils'
import { useCustomCompareEffect } from '../utils/hooks'

const InputBar = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { loading, tokenOptions, pairShow } = state

  const showLoading = () => dispatch({ type: ACTION_TYPE.showLoading })
  const hideLoading = () => dispatch({ type: ACTION_TYPE.hideLoading })

  const setPairShow = pairShow =>
    dispatch({ type: ACTION_TYPE.setPairShow, payload: { pairShow } })

  const addTokenOptions = tokens =>
    dispatch({ type: ACTION_TYPE.addTokenOptions, payload: { tokens } })
  const deleteTokenOption = token =>
    dispatch({ type: ACTION_TYPE.deleteTokenOption, payload: { token } })

  const [baseTokenInput, setBaseTokenInput] = useState('')
  const [baseSymbolInput, setBaseSymbolInput] = useState('')
  const [quoteTokenInput, setQuoteTokenInput] = useState('')
  const [quoteSymbolInput, setQuoteSymbolInput] = useState('')
  const [selectedDex, setSelectedDex] = useState('')
  const [baseTokenError, setBaseTokenError] = useState(false)
  const [quoteTokenError, setQuoteTokenError] = useState(false)
  const [dexError, setDexError] = useState(false)

  useCustomCompareEffect(
    () => {
      const { dex, baseToken, quoteToken } = pairShow
      setSelectedDex(dex || defaultDex || '')
      setDexError(false)
      setBaseTokenInput(baseToken?.address || defaultBaseToken.address || '')
      setBaseSymbolInput(baseToken?.symbol || defaultBaseToken.symbol || '')
      setBaseTokenError(false)
      setQuoteTokenInput(quoteToken?.address || defaultQuoteToken.address || '')
      setQuoteSymbolInput(quoteToken?.symbol || defaultQuoteToken.symbol || '')
      setQuoteTokenError(false)
    },
    [pairShow],
    (a, b) =>
      a[0].dex === b[0].dex &&
      a[0].baseToken?.address === b[0].baseToken?.address &&
      a[0].quoteToken?.address === b[0].quoteToken?.address
  )

  const getTokenAndPairInfo = async (dex, baseToken, quoteToken) => {
    const pair = { dex }

    await Promise.all([
      (async () => {
        try {
          pair.baseToken = await getTokenInfo(baseToken)
        } catch (err) {
          setBaseTokenError(true)
          throw new Error(`Base token ${baseToken} is invalid`)
        }
      })(),
      (async () => {
        try {
          pair.quoteToken = await getTokenInfo(quoteToken)
        } catch (err) {
          setQuoteTokenError(true)
          throw new Error(`Quote token ${quoteToken} is invalid`)
        }
      })(),
      (async () => {
        try {
          const { address } = await getPair(dex)(baseToken, quoteToken)
          pair.address = address
        } catch (err) {
          setDexError(true)
          throw new Error(
            `Pair ${baseToken}/${quoteToken} on ${dex} is invalid`
          )
        }
      })(),
    ])

    return pair
  }

  const onConfirm = async () => {
    setBaseTokenError(false)
    setQuoteTokenError(false)
    setDexError(false)

    try {
      showLoading()
      const pair = await getTokenAndPairInfo(
        selectedDex,
        baseTokenInput,
        quoteTokenInput
      )
      addTokenOptions([pair.baseToken, pair.quoteToken])
      setPairShow(pair)
    } catch (error) {
      console.error(error)
    } finally {
      hideLoading()
    }
  }

  const getSymbolFromAddress = async (address = '') => {
    if (!address.match(/^0x[0-9a-fA-F]{40}$/)) return ''

    const symbol =
      tokenOptions.find(t => t.address.toLowerCase() === address.toLowerCase())
        ?.symbol ||
      (await getTokenInfo(address)).symbol ||
      ''

    return symbol
  }

  const onChangeBaseToken = (_, value) => {
    getSymbolFromAddress(value).then(
      symbol => {
        setBaseSymbolInput(symbol)
        try {
          setBaseTokenInput(getAddress(value.toLowerCase()))
        } catch {}
      },
      e => {
        console.log(e)
        setBaseTokenError(true)
      }
    )
    setBaseTokenInput(value)
    setBaseTokenError(false)
    setDexError(false)
  }

  const onChangeQuoteToken = (_, value) => {
    getSymbolFromAddress(value).then(
      symbol => {
        setQuoteSymbolInput(symbol)
        try {
          setQuoteTokenInput(getAddress(value.toLowerCase()))
        } catch {}
      },
      e => {
        console.log(e)
        setQuoteTokenError(true)
      }
    )
    setQuoteTokenInput(value)
    setQuoteTokenError(false)
    setDexError(false)
  }

  const onSelectDex = e => {
    const newDex = e.target.value
    setSelectedDex(newDex)
    setDexError(false)
  }

  const onDeleteOption = option => e => {
    e.stopPropagation()
    deleteTokenOption(option)
  }

  const renderOption = (props, option) => {
    return (
      <Box
        {...props}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
        key={`${option.symbol}: ${option.address}`}
      >
        <Box
          component='span'
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flex: 1,
          }}
        >{`${option.symbol}: ${option.address}`}</Box>
        {!defaultTokenOptions.find(
          opt => opt.address.toLowerCase() === option.address.toLowerCase()
        ) && (
          <IconButton
            sx={{
              width: 24,
              height: 24,
            }}
            aria-label='Delete'
            onClick={onDeleteOption(option)}
          >
            <DeleteForever fontSize='small' />
          </IconButton>
        )}
      </Box>
    )
  }

  const renderTokenInput = ({
    defaultValue,
    onInputChange,
    label,
    error,
    value,
    symbol,
  }) => {
    return (
      <Autocomplete
        sx={{
          margin: { md: 1 },
          minWidth: 150,
          flex: 1,
          width: { xs: '100%' },
          padding: { xs: 1, md: 0 },
        }}
        freeSolo
        selectOnFocus
        options={tokenOptions}
        getOptionLabel={t => t.address || ''}
        renderOption={renderOption}
        filterOptions={createFilterOptions({
          stringify: t => `${t.symbol}: ${t.address}`,
        })}
        defaultValue={defaultValue}
        onInputChange={onInputChange}
        inputValue={value}
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            label={label}
            error={error}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>{symbol || ''}</InputAdornment>
              ),
            }}
          />
        )}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        width: '100%',
        // height: { md: 92 },
        alignItems: 'center',
        overflowX: 'auto',
        overflowY: 'hidden',
        paddingY: 1,
      }}
    >
      {renderTokenInput({
        defaultValue: defaultBaseToken,
        onInputChange: onChangeBaseToken,
        label: 'Base Token',
        error: baseTokenError,
        value: baseTokenInput,
        symbol: baseSymbolInput,
      })}
      {renderTokenInput({
        defaultValue: defaultQuoteToken,
        onInputChange: onChangeQuoteToken,
        label: 'Quote Token',
        error: quoteTokenError,
        value: quoteTokenInput,
        symbol: quoteSymbolInput,
      })}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: { xs: '100%', md: 'initial' },
        }}
      >
        <FormControl
          sx={{
            margin: { md: 1 },
            minWidth: 130,
            width: { xs: '100%', md: '15vw' },
            padding: { xs: 1, md: 0 },
          }}
          variant='outlined'
          error={dexError}
        >
          <InputLabel
            sx={{
              padding: { xs: 1, md: 0 },
            }}
            htmlFor='dex-select'
          >
            Exchange
          </InputLabel>
          <Select
            onChange={onSelectDex}
            value={selectedDex}
            id='dex-select'
            label={'Exchange'}
          >
            {Object.keys(DEX).map(d => (
              <MenuItem key={DEX[d]} value={DEX[d]}>
                {DEX[d]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          sx={{
            margin: 1,
            minWidth: 100,
            height: 56,
            width: '10vw',
          }}
          onClick={onConfirm}
          disabled={loading}
          variant='contained'
        >
          Confirm
        </Button>
      </Box>
    </Box>
  )
}

export default InputBar
