import { ACTION_TYPE } from '../constants/actionType'

const initialState = { rate: null, symbol: '', isUseUSDRate: false }

const initialize = (initialState = {}) => initialState

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.setUSDRate: {
      const { rate, symbol } = action.payload
      return { ...state, rate, symbol }
    }
    case ACTION_TYPE.toggleUseUSDRate: {
      return { ...state, isUseUSDRate: !state.isUseUSDRate }
    }
    case ACTION_TYPE.clearUSDRate: {
      return initialState
    }
    default: {
      return state
    }
  }
}

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
