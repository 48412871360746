import { ACTION_TYPE } from '../constants/actionType'

const initialState = {
  data: [],
  oldestBlock: Number.POSITIVE_INFINITY,
}

const initialize = (initialState = []) => initialState

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.addLogData: {
      const { data, oldestBlock } = action.payload

      return {
        ...state,
        data: [...data, ...state.data],
        oldestBlock: Math.min(state.oldestBlock, oldestBlock),
      }
    }
    case ACTION_TYPE.clearLogData: {
      return initialState
    }
    default: {
      return state
    }
  }
}

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
