import { useContext, useEffect } from 'react'
import { ACTION_TYPE } from '../constants/actionType'
import { DispatchContext, StateContext } from '../constants/contexts'
import { useCustomCompareEffect } from '../utils/hooks'

const tokenOptionsStorageKey = 'Tokens'
const favListStorageKey = 'FavList'

const LocalStorage = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { tokenOptions, favList } = state

  useEffect(() => {
    const loadStorage = () => {
      const tokenOptions = window.localStorage.getItem(tokenOptionsStorageKey)
      const favList = window.localStorage.getItem(favListStorageKey)

      dispatch({
        type: ACTION_TYPE.loadStorage,
        payload: {
          ...(!!tokenOptions && { tokenOptions: JSON.parse(tokenOptions) }),
          ...(!!favList && { favList: JSON.parse(favList) }),
        },
      })
    }

    loadStorage()

    window.addEventListener('storage', loadStorage)

    return () => window.removeEventListener('storage', loadStorage)
  }, [dispatch])

  useCustomCompareEffect(
    () => {
      window.localStorage.setItem(
        tokenOptionsStorageKey,
        JSON.stringify(tokenOptions)
      )
    },
    [tokenOptions],
    (a, b) => JSON.stringify(a[0]) === JSON.stringify(b[0])
  )

  useCustomCompareEffect(
    () => {
      window.localStorage.setItem(favListStorageKey, JSON.stringify(favList))
    },
    [favList],
    (a, b) => JSON.stringify(a[0]) === JSON.stringify(b[0])
  )
}

export default LocalStorage
