import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useContext, useMemo } from 'react'
import { StateContext } from '../constants/contexts'
import { explorerUrl, timeZoneUTC } from '../setting'
import { mapLogToTxData } from '../utils/mapping'
import { removeCommas } from '../utils/utils'

function TxTable() {
  const state = useContext(StateContext)
  const isSmallDevice = useMediaQuery(theme => theme.breakpoints.down('md'))

  const {
    loading,
    pairShow,
    logData: { data: logData },
    usdRate,
  } = state

  const { rate, symbol: symbolUSD } = usdRate

  const isShowStable = !!rate

  const columns = useMemo(
    () => [
      {
        field: 'isSell',
        valueFormatter: ({ value }) => (value ? 'Sell' : 'Buy'),
        headerName: 'Status',
        flex: 0.5,
        hide: isSmallDevice,
      },
      {
        field: 'timestamp',
        valueFormatter: ({ value }) =>
          moment.unix(value).utc().format('HH:mm:ss | D MMM'),
        headerName: 'Time',
        type: 'dateTime',
        headerAlign: 'right',
        align: 'right',
        flex: isSmallDevice ? 1.5 : 1.25,
      },
      {
        field: 'amountBase',
        headerName: 'Amount Base',
        type: 'number',
        sortComparator: (a, b) =>
          Number(removeCommas(a).split(' ')[0]) -
          Number(removeCommas(b).split(' ')[0]),
        flex: 2,
      },
      {
        field: 'amountQuotes',
        headerName: 'Amount Quote',
        type: 'number',
        sortComparator: (a, b) =>
          Number(removeCommas(a).split(' ')[0]) -
          Number(removeCommas(b).split(' ')[0]),
        flex: 2,
      },
      {
        field: 'amountQuotesUSD',
        headerName: 'Amount Quote Stable',
        type: 'number',
        sortComparator: (a, b) =>
          Number(removeCommas(a).split(' ')[0]) -
          Number(removeCommas(b).split(' ')[0]),
        flex: 2,
        hide: !isShowStable,
      },
      {
        field: 'rateSwap',
        headerName: 'Swap Rate',
        type: 'number',
        sortComparator: (a, b) =>
          Number(removeCommas(a).split(' ')[0]) -
          Number(removeCommas(b).split(' ')[0]),
        flex: 2,
      },
      {
        field: 'rateSwapUSD',
        headerName: 'Swap Rate Stable',
        type: 'number',
        sortComparator: (a, b) =>
          Number(removeCommas(a).split(' ')[0]) -
          Number(removeCommas(b).split(' ')[0]),
        flex: 2,
        hide: !isShowStable,
      },
      {
        field: 'dex',
        headerName: 'DEX',
        flex: 1,
        hide: true,
      },
      {
        field: 'pair',
        headerName: 'Pair',
        flex: 1,
        hide: true,
      },
      {
        field: 'block',
        headerName: 'Block',
        renderCell: params => (
          <Box
            component='a'
            sx={{
              textDecoration: 'none',
              color: '#42c5f5',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            target='_blank'
            rel='noreferrer'
            href={`${explorerUrl}block/${params.value}`}
          >
            {params.value}
          </Box>
        ),
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        hide: isSmallDevice,
      },
      {
        field: 'tx',
        headerName: 'Tx',
        renderCell: params => (
          <Box
            component='a'
            sx={{
              textDecoration: 'none',
              color: '#42c5f5',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            target='_blank'
            rel='noreferrer'
            href={`${explorerUrl}tx/${params.value}`}
          >
            {params.value}
          </Box>
        ),
        flex: 1,
      },
    ],
    [isShowStable, isSmallDevice]
  )

  const data = useMemo(
    () =>
      logData
        ?.map(d =>
          mapLogToTxData({
            data: d,
            baseTokenAddress: pairShow.baseToken.address || '',
            dex: pairShow.dex || '',
            timeZoneUTC,
            quoteUSDRate: rate,
            quoteUSDSymbol: symbolUSD,
          })
        )
        ?.sort((a, b) => b.timestamp - a.timestamp),
    [logData, pairShow.baseToken.address, pairShow.dex, rate, symbolUSD]
  )

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        border: 0,
        backgroundColor: '#262626',
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: '#1a1a1a',
        },
        '& .MuiDataGrid-footerContainer': {
          backgroundColor: '#1a1a1a',
        },
        '& .MuiDataGrid-row': {
          backgroundColor: '#141722',
          '&:nth-of-type(odd)': {
            backgroundColor: '#0e1119',
          },
        },
        '& .buy-row': {
          color: '#00e673',
        },
        '& .sell-row': {
          color: '#ff4d4d',
        },
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        density='compact'
        disableSelectionOnClick
        hideFooter={data.length <= 100}
        rowsPerPageOptions={[100]}
        getRowClassName={params => (params.row.isSell ? 'sell-row' : 'buy-row')}
        loading={loading}
        components={{
          LoadingOverlay: () => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <CircularProgress
                sx={{
                  marginTop: 5,
                  color: '#ffffff77',
                }}
                size={48}
                thickness={4.8}
              />
            </Box>
          ),
        }}
      />
    </Box>
  )
}

export default TxTable
