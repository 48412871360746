import { DEX } from './dex'

export const dexAddress = {
  [DEX.PancakeSwapV1]: {
    router: '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F',
    factory: '0xBCfCcbde45cE874adCB698cC183deBcF17952812',
  },
  [DEX.PancakeSwapV2]: {
    router: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    factory: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
  },
  [DEX.ApeSwap]: {
    router: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
    factory: '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6',
  },
  [DEX.Foodcourt]: {
    router: '0x0F4610aB02920a99f639F675085A5d3e24b0D7ae',
    factory: '0xc801C7980c8C7900Bc898B1F38392b235fF64097',
  },
  [DEX.Alita]: {
    router: '0x730aCC3bBf2443f2EaEaCFc7ac7b4d8DC9E32dB8',
    factory: '0xC7a506ab3ac668EAb6bF9eCf971433D6CFeF05D9',
  },
  [DEX.LatteSwap]: {
    router: '0x4Ec82EcD19529ff50a467F522029f69459bf071d',
    factory: '0x4DcE5Bdb81B8D5EdB66cA1b8b2616A8E0Dd5f807',
  },
  [DEX.Mar]: {
    router: '0x8cFD8B326FC4F76aC7B09b24926e70a1f9971953',
    factory: '0x008ac4a6cCB5455387F685528f85C8e3a00bE8a3',
  },
  [DEX.Evry]: {
    router: '0x296B7C203E2C7306b132F2D5aD66106Bb7665C46',
    factory: '0xa328180188a30feF1d82c9FC916E627DB6E17238',
  },
  [DEX.Definix]: {
    router: '0x151030a9Fa62FbB202eEe50Bd4A4057AB9E826AD',
    factory: '0x43eBb0cb9bD53A3Ed928Dd662095aCE1cef92D19',
  },
}

export const bandOracleAddress = '0xDA7a001b254CD22e46d3eAB04d937489c93174C3'
