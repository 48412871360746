export const ACTION_TYPE = {
  showLoading: 'showLoading',
  hideLoading: 'hideLoading',
  loadStorage: 'loadStorage',
  addTokenOptions: 'addTokenOptions',
  deleteTokenOption: 'deleteTokenOption',
  addFav: 'addFav',
  deleteFav: 'deleteFav',
  setPairShow: 'setPairShow',
  addLogData: 'addLogData',
  clearLogData: 'clearLogData',
  setUSDRate: 'setUSDRate',
  toggleUseUSDRate: 'toggleUseUSDRate',
  clearUSDRate: 'clearUSDRate',
}
