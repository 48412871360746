import { useEffect, useReducer, useRef } from 'react'
import {
  Box,
  createTheme,
  CssBaseline,
  Paper,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material'
import { green, lightBlue } from '@mui/material/colors'
import LineChart from './components/LineChart'
import TxTable from './components/TxTable'
import {
  getBlockNumber,
  getPair,
  getPastData,
  getQuoteRateUSD,
  getTokenInfo,
} from './utils/contractUtils'
import { intervalGetLogs } from './setting'
import { useCombinedReducer, useCustomCompareEffect } from './utils/hooks'
import { DispatchContext, StateContext } from './constants/contexts'
import {
  favListReducerArgs,
  loadingReducerArgs,
  logDataReducerArgs,
  pairShowReducerArgs,
  tokenOptionsReducerArgs,
  usdRateReducerArgs,
} from './reducerArgs'
import { ACTION_TYPE } from './constants/actionType'
import FavListBar from './components/FavListBar'
import InputBar from './components/InputBar'
import LocalStorage from './components/LocalStorage'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: lightBlue,
    secondary: green,
    background: {
      default: '#303030',
      paper: '#393939',
    },
  },
})

function App() {
  const [state, dispatch] = useCombinedReducer({
    loading: useReducer(...loadingReducerArgs),
    tokenOptions: useReducer(...tokenOptionsReducerArgs),
    favList: useReducer(...favListReducerArgs),
    pairShow: useReducer(...pairShowReducerArgs),
    logData: useReducer(...logDataReducerArgs),
    usdRate: useReducer(...usdRateReducerArgs),
  })

  const { favList, pairShow } = state

  const showLoading = () => dispatch({ type: ACTION_TYPE.showLoading })
  const hideLoading = () => dispatch({ type: ACTION_TYPE.hideLoading })

  const addLogData = (data, oldestBlock) =>
    dispatch({ type: ACTION_TYPE.addLogData, payload: { data, oldestBlock } })
  const clearLogData = () => dispatch({ type: ACTION_TYPE.clearLogData })

  const setUSDRate = payload =>
    dispatch({ type: ACTION_TYPE.setUSDRate, payload })
  const clearUSDRate = payload => dispatch({ type: ACTION_TYPE.clearUSDRate })

  const timerID = useRef()

  const unsubscribePair = () => {
    if (timerID?.current) {
      clearTimeout(timerID.current)
      console.log(`Unsubscribe Timer ID: ${timerID.current}`)
      timerID.current = null
    }
  }

  useCustomCompareEffect(
    () => {
      const { address, dex, baseToken, quoteToken } = pairShow

      if (!address) return

      console.log(
        `Subscribe Pair: ${baseToken.symbol}/${quoteToken.symbol} on ${dex}`
      )
      ;(async () => {
        try {
          showLoading()
          clearLogData()
          clearUSDRate()

          await Promise.all([
            getTokenInfo(baseToken.address),
            getTokenInfo(quoteToken.address),
            getPair(dex)(baseToken.address, quoteToken.address),
          ])

          setUSDRate(await getQuoteRateUSD(quoteToken.address))

          let currentBlock = Number(await getBlockNumber())
          const { pastLogs, oldestBlock } = await getPastData(
            address,
            null,
            currentBlock
          )
          addLogData(pastLogs, oldestBlock)

          const startTimer = () =>
            setTimeout(async () => {
              try {
                const fromBlock = currentBlock + 1
                const latestBlock = Number(await getBlockNumber())
                if (latestBlock >= fromBlock) {
                  const { pastLogs, oldestBlock } = await getPastData(
                    address,
                    fromBlock,
                    latestBlock
                  )
                  addLogData(pastLogs, oldestBlock)
                }
                currentBlock = latestBlock
              } catch (error) {
                console.error(error)
              }
              unsubscribePair()
              timerID.current = startTimer()
              console.log(`Timer ID: ${timerID.current}`)
            }, intervalGetLogs)

          unsubscribePair()
          timerID.current = startTimer()
          console.log(`Timer ID: ${timerID.current}`)
        } catch (e) {
          console.log(e)
        } finally {
          hideLoading()
        }
      })()

      return unsubscribePair
    },
    [pairShow],
    (a, b) =>
      a[0].dex === b[0].dex &&
      a[0].baseToken?.address === b[0].baseToken?.address &&
      a[0].quoteToken?.address === b[0].quoteToken?.address
  )

  useEffect(() => {
    return unsubscribePair
  }, [])

  const pairListWidth = favList?.length > 0 ? 200 : 0

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DispatchContext.Provider value={dispatch}>
          <StateContext.Provider value={state}>
            <LocalStorage />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: { md: '100vh' },
                overflow: { md: 'hidden' },
              }}
            >
              <InputBar />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  width: '100%',
                  height: { md: '60vh' },
                }}
              >
                {favList?.length > 0 && (
                  <Paper
                    sx={{
                      width: { md: pairListWidth },
                      flexShrink: 0,
                      margin: { xs: '0px 8px 8px 8px', md: '0px 0px 8px 8px' },
                      overflowY: 'auto',
                    }}
                  >
                    <FavListBar />
                  </Paper>
                )}
                <Paper
                  sx={{
                    width: {
                      md: `calc(100% - ${
                        pairListWidth ? pairListWidth + 24 : 16
                      }px)`,
                    },
                    height: { xs: '56vw', md: 'initial' },
                    margin: '0px 8px 8px 8px',
                  }}
                >
                  <LineChart />
                </Paper>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: { xs: '20rem', md: '40vh' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TxTable />
              </Box>
            </Box>
          </StateContext.Provider>
        </DispatchContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
