export const DEX = {
  PancakeSwapV1: 'PancakeSwapV1',
  PancakeSwapV2: 'PancakeSwapV2',
  ApeSwap: 'ApeSwap',
  Foodcourt: 'Foodcourt',
  Alita: 'Alita',
  LatteSwap: 'LatteSwap',
  Mar: 'Mar',
  Evry: 'Evry',
  Definix: 'Definix',
}
