import { useEffect, useRef, useState } from 'react'

export const usePersistedState = (name, defaultValue) => {
  const nameRef = useRef(name)

  const [value, setValue] = useState(() => {
    if (!window) return

    const storedValue = window.localStorage.getItem(nameRef.current)
    if (storedValue) {
      return JSON.parse(storedValue)
    } else {
      return defaultValue
    }
  })

  useEffect(() => {
    const loadStorage = () => {
      const storedValue = window.localStorage.getItem(nameRef.current)
      if (storedValue) {
        setValue(JSON.parse(storedValue))
      } else {
        setValue(defaultValue)
        window.localStorage.setItem(
          nameRef.current,
          JSON.stringify(defaultValue)
        )
      }
    }

    window.addEventListener('storage', loadStorage)

    return () => window.removeEventListener('storage', loadStorage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window?.localStorage.setItem(nameRef.current, JSON.stringify(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)])

  useEffect(() => {
    const lastName = nameRef.current
    if (name !== lastName) {
      try {
        window.localStorage.setItem(name, JSON.stringify(value))
        nameRef.current = name
        window.localStorage.removeItem(lastName)
      } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return [value, setValue]
}

export const useCombinedReducer = combinedReducers => {
  const state = Object.keys(combinedReducers).reduce(
    (acc, key) => ({ ...acc, [key]: combinedReducers[key][0] }),
    {}
  )

  const { current: dispatch } = useRef(action =>
    Object.keys(combinedReducers)
      .map(key => combinedReducers[key][1])
      .forEach(fn => fn(action))
  )

  return [state, dispatch]
}

const useCustomCompareMemo = (value, equal) => {
  const ref = useRef(value)

  if (!equal(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export const useCustomCompareEffect = (create, input, equal) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(create, [useCustomCompareMemo(input, equal)])
}
