import { getAddress } from 'ethers/lib/utils'
import { tokenPairUSD } from '../setting'

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

export const removeCommas = x => {
  return x.replace(/,/g, '')
}

export const hasPairUSD = token =>
  tokenPairUSD[getAddress(token.toLowerCase())] &&
  !tokenPairUSD[getAddress(token.toLowerCase())].isStable
