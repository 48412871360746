export const mapLogToChartData = ({
  data,
  baseTokenAddress,
  timeZoneUTC = 0,
  quoteUSDRate,
}) => {
  const time = new Date(data.timestamp * 1000)
  time.setHours(time.getHours() + timeZoneUTC)

  const value =
    baseTokenAddress.toLowerCase() === data.token0.address.toLowerCase()
      ? data.rateSwap0
      : data.rateSwap1

  return {
    time: time.getTime() / 1000,
    value: quoteUSDRate
      ? value.times(quoteUSDRate).toNumber()
      : value.toNumber(),
  }
}

export const mapLogToTxData = ({
  data,
  baseTokenAddress,
  dex,
  timeZoneUTC = 0,
  quoteUSDRate,
  quoteUSDSymbol,
}) => {
  const isToken0Base =
    baseTokenAddress.toLowerCase() === data.token0.address.toLowerCase()

  const time = new Date(data.timestamp * 1000)
  time.setHours(time.getHours() + timeZoneUTC)

  const pair = isToken0Base
    ? `${data.token0.symbol}/${data.token1.symbol}`
    : `${data.token1.symbol}/${data.token0.symbol}`
  const isSell = isToken0Base ? data.is0to1 : !data.is0to1
  const amountBase = isToken0Base
    ? { value: data.amount0, symbol: data.token0.symbol }
    : { value: data.amount1, symbol: data.token1.symbol }
  const amountQuote = isToken0Base
    ? { value: data.amount1, symbol: data.token1.symbol }
    : { value: data.amount0, symbol: data.token0.symbol }
  const rateSwap = isToken0Base ? data.rateSwap0 : data.rateSwap1

  return {
    id: `${data.txHash}-${rateSwap}`,
    timestamp: time.getTime() / 1000,
    block: data.blockNumber,
    tx: data.txHash,
    dex,
    pair,
    isSell,
    amountBase: `${amountBase.value.toFormat(9)} ${amountBase.symbol}`,
    amountQuotes: `${amountQuote.value.toFormat(9)} ${amountQuote.symbol}`,
    amountQuotesUSD: quoteUSDRate
      ? `${amountQuote.value.times(quoteUSDRate).toFormat(9)} ${quoteUSDSymbol}`
      : '-',
    rateSwap: `${rateSwap.toFormat(9)} ${amountQuote.symbol}`,
    rateSwapUSD: quoteUSDRate
      ? `${rateSwap.times(quoteUSDRate).toFormat(9)} ${quoteUSDSymbol}`
      : '-',
  }
}
