import { ACTION_TYPE } from '../constants/actionType'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.setPairShow: {
      const { pairShow } = action.payload

      return { ...state, ...pairShow }
    }
    default: {
      return state
    }
  }
}

const initialState = {
  address: '',
  dex: '',
  baseToken: { address: '', symbol: '' },
  quoteToken: { address: '', symbol: '' },
}

const initialize = (initialState = {}) => initialState

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
