import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { formatUnits, getAddress } from 'ethers/lib/utils'
import { erc20ABI, factoryABI, pairABI } from '../abi'
import { dexAddress } from '../constants/address'
import {
  fullNumPastBlock,
  maxGetPastLogsPerStart,
  minNumPastLogsPerStart,
  rpcUrl,
  tokenPairUSD,
} from '../setting'
import { hasPairUSD } from './utils'

// tokens: {
//   [tokenAddress]: {
//     address,
//     name,
//     symbol,
//     decimals,
//     logo,
//   }
// }
const tokens = {}

// pairs: {
//   [pairAddress]: {
//     address,
//     dex,
//     token0,
//     token1,
//   }
// }
const pairs = {}

const provider = new ethers.providers.JsonRpcProvider(rpcUrl)

export const getTokenInfo = async address => {
  address = getAddress(address.toLowerCase())

  if (tokens[address]) return tokens[address]

  const erc20Contract = new ethers.Contract(address, erc20ABI, provider)

  try {
    const name = await erc20Contract.name()
    const symbol = await erc20Contract.symbol()
    const decimals = await erc20Contract.decimals()
    tokens[address] = { address, name, symbol, decimals }
  } catch (e) {
    throw new Error(`${address} is not token`)
  }

  console.log(tokens[address])

  return tokens[address]
}

export const getPair = dex => async (tokenA, tokenB) => {
  tokenA = getAddress(tokenA.toLowerCase())
  tokenB = getAddress(tokenB.toLowerCase())

  if (tokenA === tokenB) {
    throw new Error('Token cannot be the same')
  }

  const [token0, token1] =
    tokenA.toLowerCase() < tokenB.toLowerCase()
      ? [tokenA, tokenB]
      : [tokenB, tokenA]

  const pair = Object.values(pairs).find(
    p => p.token0 === token0 && p.token1 === token1 && p.dex === dex
  )
  if (pair) return pair

  const contract = new ethers.Contract(
    dexAddress[dex].factory,
    factoryABI,
    provider
  )

  const pairAddress = await contract.getPair(token0, token1)

  if (pairAddress === ethers.constants.AddressZero) {
    throw new Error('No LP pair found')
  }

  pairs[pairAddress] = { address: pairAddress, dex, token0, token1 }

  console.log(pairs[pairAddress])

  return pairs[pairAddress]
}

export const getTokenFromPair = async pairAddress => {
  pairAddress = getAddress(pairAddress.toLowerCase())
  const pair = pairs[pairAddress]
  if (pair) return pair

  const contract = new ethers.Contract(pairAddress, pairABI, provider)

  const token0 = await contract.token0()
  const token1 = await contract.token1()
  const factory = await contract.factory()

  const dex =
    Object.entries(dexAddress).find(
      e => e[1].factory.toLowerCase() === factory.toLowerCase()
    )?.[0] || ''

  pairs[pairAddress] = { address: pairAddress, dex, token0, token1 }

  console.log(pair[pairAddress])

  return pairs[pairAddress]
}

export const getPairRate = async pairAddress => {
  pairAddress = getAddress(pairAddress.toLowerCase())

  const { token0: token0Address, token1: token1Address } = pairs[pairAddress]

  const token0 = await getTokenInfo(token0Address)
  const token1 = await getTokenInfo(token1Address)

  const pairContract = new ethers.Contract(pairAddress, pairABI, provider)
  const [reserve0, reserve1] = await pairContract.getReserves()

  const amount0 = formatUnits(reserve0, token0.decimals)
  const amount1 = formatUnits(reserve1, token1.decimals)

  return {
    reserve0,
    reserve1,
    rate0: new BigNumber(amount1).div(amount0),
    rate1: new BigNumber(amount0).div(amount1),
  }
}

export const getBaseRate = async (baseAddress, quoteAddress, dex) => {
  const { address, token0 } = await getPair(dex)(baseAddress, quoteAddress)
  const { rate0, rate1 } = await getPairRate(address)

  return token0 === baseAddress ? rate0 : rate1
}

const decodeSwapLog = async log => {
  const {
    address: pairAddress,
    token0: token0Address,
    token1: token1Address,
  } = await getTokenFromPair(log.address)

  const token0 = await getTokenInfo(token0Address)
  const token1 = await getTokenInfo(token1Address)

  const logArgs = log.args
  const amount0In = formatUnits(logArgs.amount0In, token0.decimals)
  const amount1In = formatUnits(logArgs.amount1In, token1.decimals)
  const amount0Out = formatUnits(logArgs.amount0Out, token0.decimals)
  const amount1Out = formatUnits(logArgs.amount1Out, token1.decimals)

  const isToken0In = new BigNumber(amount0In).gt(amount0Out)
  const isToken1In = new BigNumber(amount1In).gt(amount1Out)
  const is0to1 = isToken0In && !isToken1In

  const amount0 = new BigNumber(amount0In).minus(amount0Out).abs()
  const amount1 = new BigNumber(amount1In).minus(amount1Out).abs()

  const timestamp = await getTimestampFromBlockNumber(log.blockNumber)

  return {
    timestamp,
    blockNumber: log.blockNumber,
    txHash: log.transactionHash,
    pairAddress,
    token0,
    token1,
    rateSwap0: amount1.div(amount0),
    rateSwap1: amount0.div(amount1),
    amount0: amount0,
    amount1: amount1,
    is0to1,
  }
}

export const getPastLogs = async (
  pairAddress,
  fromBlock,
  toBlock = 'latest'
) => {
  const contract = new ethers.Contract(pairAddress, pairABI, provider)

  const logs = await contract.queryFilter('Swap', fromBlock, toBlock)

  return await Promise.all(logs.map(async log => await decodeSwapLog(log)))
}

export const getBlockNumber = async () => {
  return await provider.getBlockNumber()
}

export const getTimestampFromBlockNumber = async blockNumber => {
  return (await provider.getBlock(blockNumber)).timestamp
}

export const getQuoteRateUSD = async quoteToken => {
  quoteToken = getAddress(quoteToken.toLowerCase())

  if (hasPairUSD(quoteToken)) {
    const {
      baseToken: { address: bAddr },
      quoteToken: { address: qAddr, symbol: qSymbol },
      dex,
    } = tokenPairUSD[quoteToken]
    return { rate: await getBaseRate(bAddr, qAddr, dex), symbol: qSymbol }
  }
  return {}
}

export const getPastData = async (pairAddress, fromBlock, toBlock) => {
  let pastLogs = []
  let oldestBlock = toBlock

  if (fromBlock) {
    console.log(
      `Getting Pair ${pairAddress} Tx ${
        toBlock - fromBlock + 1
      } blocks (${fromBlock}-${toBlock})`
    )

    pastLogs = await getPastLogs(pairAddress, fromBlock, toBlock)
    oldestBlock = fromBlock
  } else {
    let count = 0
    let endBlock = toBlock
    let numPastBlock = fullNumPastBlock

    while (
      pastLogs.length <= minNumPastLogsPerStart &&
      count < maxGetPastLogsPerStart
    ) {
      try {
        const startBlock = endBlock - numPastBlock

        console.log(
          `Getting Pair ${pairAddress} Tx ${
            endBlock - startBlock + 1
          } blocks (${startBlock}-${endBlock})`
        )

        const logs = await getPastLogs(pairAddress, startBlock, endBlock)

        pastLogs = [...logs, ...pastLogs]
        oldestBlock = startBlock

        endBlock = startBlock - 1
      } catch (error) {
        console.error(error)
        numPastBlock = Math.floor(numPastBlock / 2)
      } finally {
        count++
      }
    }
  }

  console.log(`New Tx: ${pastLogs.length}`)

  return { pastLogs, oldestBlock }
}
