import { ACTION_TYPE } from '../constants/actionType'
import { defaultTokenOptions } from '../setting'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.addTokenOptions: {
      const { tokens } = action.payload

      return [
        ...state,
        ...tokens.filter(
          t =>
            !state.find(
              opt => opt.address.toLowerCase() === t.address.toLowerCase()
            )
        ),
      ].sort((a, b) =>
        a.symbol.toLowerCase().localeCompare(b.symbol.toLowerCase())
      )
    }
    case ACTION_TYPE.deleteTokenOption: {
      const { token } = action.payload
      return state.filter(
        opt => opt.address.toLowerCase() !== token.address.toLowerCase()
      )
    }
    case ACTION_TYPE.loadStorage: {
      const { tokenOptions } = action.payload
      const tokens =
        tokenOptions?.filter(
          t =>
            !state.find(
              opt => opt.address.toLowerCase() === t.address.toLowerCase()
            )
        ) ?? []
      return [...state, ...tokens].sort((a, b) =>
        a.symbol.toLowerCase().localeCompare(b.symbol.toLowerCase())
      )
    }
    default: {
      return state
    }
  }
}

const initialState = []

const initialize = (initialState = []) => {
  const tokens = defaultTokenOptions.filter(
    t =>
      !initialState.find(
        opt => opt.address.toLowerCase() === t.address.toLowerCase()
      )
  )
  return [...initialState, ...tokens].sort((a, b) =>
    a.symbol.toLowerCase().localeCompare(b.symbol.toLowerCase())
  )
}

const reducerArgs = [reducer, initialState, initialize]

export default reducerArgs
