import { Box } from '@mui/material'
import { useState } from 'react'

function ImageOnLoad(props) {
  const [loaded, setLoaded] = useState(false)

  return (
    <Box
      component='img'
      alt=''
      {...props}
      style={loaded ? props.style : { ...props.style, display: 'none' }}
      onLoad={() => setLoaded(true)}
    />
  )
}

export default ImageOnLoad
