import { Box, Button, CircularProgress, IconButton } from '@mui/material'
import { Star, OpenInNew, StarBorder } from '@mui/icons-material'
import { amber, grey } from '@mui/material/colors'
import { createChart, CrosshairMode } from 'lightweight-charts'
import moment from 'moment'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { explorerUrl, timeZoneUTC } from '../setting'
import ImageOnLoad from './ImageOnLoad'
import { DispatchContext, StateContext } from '../constants/contexts'
import { ACTION_TYPE } from '../constants/actionType'
import { mapLogToChartData } from '../utils/mapping'
import { getPastData, getQuoteRateUSD } from '../utils/contractUtils'

function LineChart() {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const {
    loading,
    favList,
    pairShow,
    logData: { data: logData, oldestBlock },
    usdRate,
  } = state

  const { address: pairAddress, dex, baseToken, quoteToken } = pairShow
  const { rate: rateUSD, symbol: symbolUSD, isUseUSDRate } = usdRate

  const showLoading = () => dispatch({ type: ACTION_TYPE.showLoading })
  const hideLoading = () => dispatch({ type: ACTION_TYPE.hideLoading })

  const addFav = pair =>
    dispatch({ type: ACTION_TYPE.addFav, payload: { pair } })
  const deleteFav = pair =>
    dispatch({ type: ACTION_TYPE.deleteFav, payload: { pair } })

  const setUSDRate = ({ rate, symbol }) =>
    dispatch({ type: ACTION_TYPE.setUSDRate, payload: { rate, symbol } })
  const toggleUseUSDRate = () =>
    dispatch({ type: ACTION_TYPE.toggleUseUSDRate })

  const chartContainerRef = useRef()
  const chart = useRef()
  const resizeObserver = useRef()
  const lineSeries = useRef()
  const lastTime = useRef()
  const lastRate = useRef()

  const [legendTime, setLegendTime] = useState('')
  const [legendRate, setLegendRate] = useState('')

  const addLogData = (data, oldestBlock) =>
    dispatch({ type: ACTION_TYPE.addLogData, payload: { data, oldestBlock } })

  useEffect(() => {
    if (chart.current) return

    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      localization: {
        locale: 'en-US',
      },
      layout: {
        backgroundColor: '#253248',
        textColor: 'rgba(255, 255, 255, 0.9)',
      },
      grid: {
        vertLines: {
          color: '#334158',
        },
        horzLines: {
          color: '#334158',
        },
      },
      crosshair: { mode: CrosshairMode.Normal },
      priceScale: {
        borderColor: '#485c7b',
      },
      timeScale: {
        borderColor: '#485c7b',
        timeVisible: true,
        secondsVisible: true,
      },
    })

    lineSeries.current = chart.current.addLineSeries({
      crosshairMarkerRadius: 6,
      priceFormat: {
        precision: 9,
        minMove: 0.000000001,
      },
    })

    chart.current.subscribeCrosshairMove(param => {
      if (param && param.point && param.time) {
        setLegendTime(param.time)
        setLegendRate(param.seriesPrices.get(lineSeries.current))
      } else {
        setLegendTime(lastTime.current)
        setLegendRate(lastRate.current)
      }
    })
  }, [])

  useEffect(() => {
    resizeObserver.current = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect
      chart.current.applyOptions({ width, height })
      setTimeout(() => {
        chart.current.timeScale().fitContent()
      }, 0)
    })

    resizeObserver.current.observe(chartContainerRef.current)

    return () => resizeObserver.current.disconnect()
  }, [])

  const data = useMemo(
    () =>
      logData
        ?.map(d =>
          mapLogToChartData({
            data: d,
            baseTokenAddress: baseToken.address || '',
            timeZoneUTC,
            quoteUSDRate: isUseUSDRate && rateUSD,
          })
        )
        ?.sort((a, b) => a.time - b.time)
        ?.filter(
          (data, i, a) => i === a.length - 1 || data.time !== a[i + 1].time
        ),
    [baseToken.address, isUseUSDRate, logData, rateUSD]
  )

  useEffect(() => {
    const { time, value } = data[data.length - 1] ?? {}
    lastTime.current = time
    lastRate.current = value
    setLegendTime(time)
    setLegendRate(value)

    lineSeries.current?.setData(data)
  }, [data])

  const onClickFav = fav => () => {
    if (fav) {
      addFav(pairShow)
    } else {
      deleteFav(pairShow)
    }
  }

  const onClickUSDRate = async () => {
    if (!isUseUSDRate) {
      showLoading()
      setUSDRate(await getQuoteRateUSD(quoteToken.address))
      hideLoading()
    }
    toggleUseUSDRate()
  }

  const onClickPairLink = () => {
    window.open(`${explorerUrl}address/${pairAddress}`)
  }

  const onClickLoadMore = async () => {
    showLoading()
    const { pastLogs, oldestBlock: oldestBlk } = await getPastData(
      pairAddress,
      null,
      oldestBlock - 1
    )
    addLogData(pastLogs, oldestBlk)
    hideLoading()
    setTimeout(() => {
      chart.current.timeScale().fitContent()
    }, 0)
  }

  const isFav = favList.some(
    fav =>
      fav.dex === dex &&
      fav.baseToken.address === baseToken?.address &&
      fav.quoteToken.address === quoteToken?.address
  )

  const chartRateSymbol = isUseUSDRate
    ? symbolUSD || quoteToken?.symbol
    : quoteToken?.symbol

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      {!!baseToken.address && !!quoteToken.address && (
        <Box
          sx={{
            margin: 2,
            color: 'white',
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '1rem', md: '1.5rem' },
              marginBottom: 1.5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {isFav ? (
              <IconButton
                edge='start'
                style={{ color: amber[500] }}
                onClick={onClickFav(false)}
              >
                <Star />
              </IconButton>
            ) : (
              <IconButton
                edge='start'
                style={{ color: grey[500] }}
                onClick={onClickFav(true)}
              >
                <StarBorder />
              </IconButton>
            )}

            <Box
              component='span'
              sx={{
                m: 0.5,
              }}
            >{`${dex}`}</Box>
            <Box
              component='span'
              sx={{
                m: 0.5,
              }}
            >
              {':'}
            </Box>
            <ImageOnLoad
              sx={{
                margin: 0.5,
                height: { xs: '1rem', md: '1.5rem' },
              }}
              src={baseToken.logo}
            />
            <Box
              component='span'
              sx={{
                m: 0.5,
              }}
            >
              {baseToken.symbol}
            </Box>
            <Box
              component='span'
              sx={{
                m: 0.5,
              }}
            >
              {'/'}
            </Box>
            <ImageOnLoad
              sx={{
                margin: 0.5,
                height: { xs: '1rem', md: '1.5rem' },
              }}
              src={quoteToken.logo}
            />
            <Box
              component='span'
              sx={{
                m: 0.5,
              }}
            >
              {quoteToken.symbol}
            </Box>
            <IconButton
              onClick={onClickPairLink}
              size='small'
              style={{ color: grey[500] }}
            >
              <OpenInNew fontSize='small' />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                opacity: 0.6,
                fontSize: { xs: '0.75rem', md: '1rem' },
              }}
            >
              <Box>
                {legendTime
                  ? moment.unix(legendTime).utc().format('ddd D MMM YY')
                  : ''}
              </Box>
              <Box>
                {legendTime
                  ? moment.unix(legendTime).utc().format('HH:mm:ss')
                  : ''}
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: { xs: '1rem', md: '1.5rem' },
                marginLeft: 2,
              }}
            >
              {legendRate ? Number(legendRate).toFixed(9) : ''}
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          zIndex: 10,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {!loading && !!data?.length && (
            <Button
              sx={{
                zIndex: 10,
                margin: 2,
                backgroundColor: '#424242',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#666666',
                },
              }}
              onClick={onClickLoadMore}
            >
              {'<< More'}
            </Button>
          )}
        </Box>
        <Box>
          {!!chartRateSymbol && (
            <Button
              sx={{
                margin: 2,
                backgroundColor: '#424242',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#666666',
                },
                '&.Mui-disabled': {
                  color: '#FFFFFF',
                },
              }}
              onClick={onClickUSDRate}
              disabled={loading || !rateUSD}
            >
              {chartRateSymbol}
            </Button>
          )}
        </Box>
      </Box>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 20,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress
            sx={{
              color: '#6581af',
            }}
            size={48}
            thickness={4.8}
          />
        </Box>
      )}
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
        ref={chartContainerRef}
      />
    </Box>
  )
}

export default LineChart
