import { Star } from '@mui/icons-material'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import { amber } from '@mui/material/colors'
import { useContext } from 'react'
import { ACTION_TYPE } from '../constants/actionType'
import { DispatchContext, StateContext } from '../constants/contexts'

const FavListBar = () => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const { loading, favList, pairShow } = state

  const { dex, baseToken, quoteToken } = pairShow

  const deleteFav = pair =>
    dispatch({ type: ACTION_TYPE.deleteFav, payload: { pair } })

  const setPairShow = pairShow =>
    dispatch({ type: ACTION_TYPE.setPairShow, payload: { pairShow } })

  return (
    <List dense component='nav'>
      {favList.map(fav => (
        <ListItem
          button
          key={`${fav.baseToken.address}/${fav.quoteToken.address}/${fav.dex}`}
          onClick={() => setPairShow(fav)}
          disabled={loading}
          selected={
            fav.dex === dex &&
            fav.baseToken.address === baseToken?.address &&
            fav.quoteToken.address === quoteToken?.address
          }
        >
          <ListItemText
            primary={`${fav.baseToken.symbol} / ${fav.quoteToken.symbol}`}
            secondary={fav.dex}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge='end'
              style={{ color: amber[500] }}
              onClick={() => deleteFav(fav)}
            >
              <Star />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}

export default FavListBar
